.webOrderInvoice {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px lightgrey;
    margin: 15px;
    margin-top: 35px;
    padding: 15px;
    min-width: 30%;
    align-items: flex-start;
    max-height: 400px;
    min-height: 400px;
    max-width: 30%;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}

.invoiceNodes {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 0px;
    width: 100%
}
.nodeNumberAndQty {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: 5px
}

.invoiceNodes > div {
    margin-left: 5%
}

.invoiceQuantity > p:last-child{
margin-left: 10%
}

.invoiceCustomer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    
}
.invoiceCustomer > p {
    font-size: small;
}
.invoiceExtras {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    max-width: 100%;
    white-space: nowrap;
}
.invoiceExtras > div{
    display: flex;
    width: 100%;
    justify-content: space-between;

}
.additionalComments {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%
}

.additionalComments > :last-child {
    margin-top: 5px;
    white-space: normal;
   
}


.sensorOrderList {
    margin-left: 20px
}