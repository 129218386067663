.graphSectionContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    /* background-color: slateblue; */
}

.graphSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50vh;
    justify-content: space-between;
    align-items: stretch;
    margin: 5px;
    padding: 15px
}

.dropdownArea {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.nodeDropdownThing {
    display: flex;
    height: 50px;
    margin: 5px;
    justify-content: center;
    align-items: center;
    /* background-color: seagreen; */
}

.parameterDropdown {
    display: flex;
    height: 50px;
    margin: 5px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

}

.datePreset {
    display: flex;
    flex:1;
    justify-content: flex-end;
    align-items: center;
}

#dateRangePreset .MuiToggleButton-root.Mui-selected {
    background-color: #006888;
    color: white;
}

.graphArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.graphLoading {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin: auto;
    color: #006888;
    /* background-color: thistle; */
}



.csvButtonArea {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

