.nodeInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 20px;
  margin-top: 10px;
  background-color: whitesmoke;
  width: 90%;
}

.nodeInfoStub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nodeInfoStub > p:first-of-type {
  font-weight: 600;
  font-size: 2em;
}

.projectName {
  display: flex;
  margin-top: 7px;
}

.projectName > p:first-of-type {
  font-weight: 600;
  margin-right: 8px;
}

.nodeOptionButtons {
  display: flex;
}

.nodeOptionButtons > p {
  margin-left: 8px;
}

.cropInfoStub {
  display: flex;
  justify-content: space-between;
  width: 55%;
}

.cropInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 23px;
}

.cropInfo > p:first-of-type {
  font-weight: 600;
}

.shadow {
  box-shadow: 0 0 5px #dadada;
  border: 1px grey solid;
}

.optionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 2rem;
}

.nodeDataArea {
  width: 90%;
  padding: 0px;
}

.graphArea {
  display: flex;
  justify-content: space-between;
  padding-right: 10%;
}

.graphButtons {
  display: flex;
  flex-direction: column;
  max-height: 350px;
  min-width: 200px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 20px;
}

.graphButtons > button {
  margin-top: 8px;
}

.nodeDropdown {
  display: flex;
  flex-direction: row;
  min-width: 200px;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  margin-left: 20%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.nodeMap {
  width: 80%;
  display: flex;
  justify-content: inherit;
}

.csvButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 25px;
}

.csvButton > button {
  display: flex;
  justify-content: space-between;
}

.loadingGraphArea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  padding: 30px;
  margin-left: 20px;
}

.loadingGraph {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgrey;
  width: 90%;
  height: 50vh;
}

@media only screen and (max-width: 600px) {
  .nodeInfoHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: whitesmoke;
    width: 90%;
  }

  .graphArea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
  }

  .graphSpot {
    display: flex;
    max-width: 100%;
    height: auto;
  }

  .graphButtons {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
    padding: 10px;
  }
}
