.nodeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  /* padding: 10px;
  height: 50%; */
  width: 85%;
  /* overflow: scroll; */
  /* background-color: tomato; */
}

.selectNodeTitle {
  font-size: large;
}

.selectNodeContainer{
  display: inline-block;
  height: 20vh;
  width: 100%;
  overflow-y: scroll;
  border: 1px black solid;
}

.tableHeader{
 padding: 1rem 4rem 1rem 4rem;
}

.deviceTable {
  width: 100%;
  height: 100%;
}

.deviceTable > thead {
  border-bottom: 1px solid black;
  width: 50%;
}

.deviceRows {
  border-bottom: 1px solid darkgrey;
  cursor: pointer;
}

.deviceRows:hover{
  background-color: rgb(237, 237, 237);
}
