.footer-nav {
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: center;
    padding-top: 10px;
    margin-top: 100px;
  }
  
  .footer-nav-link {
    text-transform: none;
    background-color: transparent;
    color: #393636;
  }

  .minnesota-logo{
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .informatics-logo{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 250px;
    float: right;
    margin-right: 3%;
  }