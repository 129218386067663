* {
  box-sizing: border-box;
}
/* CSS Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/**
	GEMS STYLES
*/
textarea {
  resize: none;
}

:root {
  font-family: 'Open Sans', sans-serif;

}
button > a {
  color: white
}

th,
h1 {
  text-transform: uppercase;
}

h2 {
  font-size: 30px;
  margin-bottom: 5px;
}

td,
th,
button,
p,
h1,
h2,
a,
li {
  font-size: 12pt;
}

a {
  color: #006888;
  text-decoration: none;
}

a:hover {
  color: #138e8f;
  text-decoration: none;
}

button {
  text-transform: uppercase;

  /* Bootstrap btn styles */
  padding: 6px 12px;
  margin-bottom: 0;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0rem;
  height: 30px;
}

button:disabled {
  background-color: lightgray;
  cursor: default;
}
.buttonLink {
  height: 30px;
  padding: 6px 12px;
  text-transform: uppercase;

}
.semi-bold {
  font-weight: 600;
}

input {
  min-height: 30px;
  border-radius: 0%;
  border: 1px grey solid;
  padding-left: 5px;
}

button.nav-item.nav-link.active {
  margin-left: 12px;
}

/**
  the following are experimental 
*/

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px; 
}

.circle {
  border-radius: 50%;
}
.title {
  font-weight: 600;
  font-size: x-large;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 20px
}
.titleLite {
  font-size: x-large;
  margin-bottom: 20px
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee;
}

.no-outline:focus {
  outline: none;
}

.image-container{
  /* you need to match the shadow color to your background or image border for the desired effect*/
  box-shadow: 0 0 8px 8px white inset;
}

/* COLORS */
/* Background */
.transparent {
  background-color: transparent;
}
.plum {
  background-color: #862e50;
}
.red {
  background-color: #b5322e;
}
.orange {
  background-color: #f05a28;
}
.gold {
  background-color: #f7931d;
}
.lime {
  background-color: #bfb93e;
}
.grass {
  background-color: #69a03d;
}
.green {
  background-color: #328d41;
}
.teal {
  background-color: #138e8f;
}
.blue {
  background-color: #006888;
}
.lightblue {
  background-color: #ebf3f6;
}
.clay {
  background-color: #6d503d;
}
.brown {
  background-color: #59381f;
}
.grey {
  background-color: #5c5d5c;
}
.soot {
  background-color: #393636;
}
.lightgrey {
  background-color: #f6f6f6;
}

/* Text */
.white-text {
  color: #fff;
}
.red-text {
  color: #b5322e;
}
.plum-text {
  color: #862e50;
}
.teal-text {
  color: #138e8f;
}
.blue-text {
  color: #006888;
}
.gold-text {
  color: #f7931d;
}
.grass-text {
  color: #69a03d;
}
.soot-text {
  color: #393636;
}
.grey-text {
  color: #5c5d5c;
}
