.loginContainer {
        width: 60%;
        display: flex;
        flex-direction: column;
        border: 1px darkgrey solid;
        justify-content: space-evenly;
        align-items: center;
        max-width: 400px;
        min-height: 300px;
        margin: 10px;
        box-shadow: 0 0 10px #dfdddd;
        padding: 16px;
}

.loginImage {
        width: 200px;
        height: auto;
}