.warning{
  padding: 1rem 2rem 1rem 2rem;
  text-align: left;
  max-width: 50%;
  border-color: red;
  margin-top: 2rem;
}

.registerNodePrereq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  margin-bottom: 20px;
}

.registerNodePrereq > li {
  margin: 3px;
}

.registerNodeBottomSection {
  display: flex;
  align-self: stretch;
  justify-content: center;
  /* background-color: lightpink; */
  margin-top: 20px;
}

.registerNodeDirections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: seagreen; */
}

.registerNodeDirections > li {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  text-align: left;
}

.registerNodeDirections > li > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.registerDownloadButtons {
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: space-evenly;
  margin: 10px;
}

.registerNodePicArea {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  padding: 15px;
}

.registerNodePicArea > p {
  margin-top: 10px;
}

.registerNodePic {
  width: 50%;
  align-self: center;
}

.importantNotes {
  display: flex;
  flex-direction: column;
  background-color: #ebf3f6;

  padding: 15px;
  height: fit-content;
}
.importantNotes > p {
  margin-top: 15px;
  margin-bottom: 15px;
}

.dividerLineHorizontal {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px dashed black;
  margin: 1em 0;
  padding: 0;
  width: 200px;
}

.nodeRegistrationForm {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 400px;
  flex-direction: row;
  padding: 2rem;
  padding-top: 0;
}

.nodeRegisterPage {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.nodeSetupInstructions {
  max-width: 700px;
}

.registerNodeDecor {
  position: fixed;
  right: 0px;
  max-width: 50%;
  z-index: -1;
}

@media only screen and (max-width: 700px) {
  /* For mobile phones: */
  .registerNodeDecor {
    max-width: 60%;
    bottom: 0px;
    opacity: 0.4;
  }

  .nodeRegisterPage {
    align-items: center;
  }
}
.registerNodePage {
  padding: 1rem 2rem 1rem 2rem;
}