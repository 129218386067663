.orderingArea {
    display: flex;
    margin-top: 35px;
    height: 400px;
    width: 80%;
    box-shadow: 0 0 10px lightgrey;
    justify-content: center;
    /* padding: 10px */
}

.orderFormTitle{
    font-size: larger;
    margin-top: 15px;
    margin-bottom: 25px;
}

.orderSteps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 15px
}
.cardInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.webOrderCustomerInfo {
    width: 100%;
    margin-left: 25px;
}
.webOrderSensorSelection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* height: 10vh; */
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 12px;
    margin-bottom: 12px;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    overflow-x: hidden;
    background-color: white;

}
.sensorChoices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px;
}

.sensorOption {
    display: flex;
    align-items: center;
}


.webOrderSensorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ebf3f6;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.webOrderExtras {
    display: flex;
    flex-shrink: 2;
    flex-direction: row;
    align-items: flex-start;
    padding: 30px;
}

.orderContactImg {
     width: 100%;
    max-height: auto;
}

.webOrderExtras > button {
align-self: center;
}

.orderExtraItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 70px;
    justify-content: space-around;
}

.orderPage {
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.paperOnTable {
    width: 100%;
    max-height: auto;
}
.orderExtraInfo {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    width: 70%;
    align-items: stretch;
    text-align: left;
}

.sensorsOnNode > h1 {
    margin-bottom: 5px;
}
.sensorsOnNode > p {
    margin-top: 3px;
    margin-left: 15px
}
.quantityModal > p {
    font-style: italic;
}