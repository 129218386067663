.stepContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  min-width: 100%;
}

.stepperArea {
    display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
}

