.projectInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 90%;
}
.addProjectSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}

.addProjectSection > input {
  width: 50%;
}
