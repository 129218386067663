.row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.col{
    flex: 1 1 auto;
}

.tableBtn{
    all: unset;
    margin:auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    /* Not sure what color to place here? */
    color: #006888;
}

.pl-1{
    padding-left: 0.5em;
}

/* Just to be a little more responsive */
@media screen and (max-width: 1200px){
    .columnPadding{
        margin-left: 2em;
    }
}

@media screen and (max-width: 600px){
    .columnPadding{
        margin-left: 1em;
    }
}
