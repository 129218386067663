.farmCard {
  display: flex;
  flex-direction: column;
  border: 1px darkgrey solid;
  justify-content: stretch;
  min-width: 275px;
  max-width: 275px;
  min-height: 300px;
  margin: 10px;
  box-shadow: 0 0 10px #dfdddd;
  padding-top: 0px;
}

.farmCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-left: 30px;

  padding-bottom: 30px;
  text-align: left;
  color: black;
  max-height: 300px;
  margin-top: 10px;
}

.farmCardProject {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.farmCardProject > p {
  margin-bottom: 10px;
}

.dataBubble > p {
  margin-left: 10px;
}

.currentInfo > p {
  margin-left: 10%;
}

@media only screen and (max-width: 600px) {
  .farmCard {
    display: flex;
    flex-direction: column;
    border: 1px darkgrey solid;
    max-width: 600px;
    min-height: 300px;
    margin: 10px;
  }
}

.farmCardData {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-top: 10px;
}

.projDescription {
  text-overflow: ellipsis;
  word-wrap: normal;
  margin-right: 15px;
}

.project-card-action-buttons {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem;
}
