.helpContactForm {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    max-width: 500px;
    min-height: 300px;
    margin: 10px;
    padding: 16px;
}

.helpForm {
    padding: 1rem 2rem 1rem 2rem;
}