.fieldSetupContainer {
    display: flex;
    flex-direction: column;
    border: 1px darkgrey solid;
    box-shadow: 0 0 10px #dfdddd;
    justify-content: space-evenly;
    max-width: 60%;
    min-height: 200px;
    margin: 10px;
    padding: 20px;
    text-align: left;
  
  }

  .fieldSetupContainer > div {
    align-self: center
  }