.nav-tabs {
  margin-top: 10px;
  margin-left: 5px;
  border-color: transparent;
  background-color: transparent;
}
#setupNav {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
width: 100%
}

#setupTabContent {
  margin: 30px;
}

button.nav-item.nav-link.active {
  border-bottom-width: 3px;
  border-bottom-color: #69a03d;
  outline: 0;
}

button.nav-item.nav-link {
  background-color: transparent;
  text-transform: initial;
}
button.nav-item.nav-link:hover {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
