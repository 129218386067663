.nodeSetupRedirectContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-top: 50px;
    height: 50vh;
    width: 100%;
    align-items: center;
    
  }
  .nodeSetupRedirectContainer > p {
    font-size: x-large;
  }

  .nodeButtonArea {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    justify-content: space-around;
    margin-top: 50px;
    height: 30%
  }
  .nodeButtonArea > h1 {
    font-size: x-large;
  }

  .nodeButtons {
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-self: center;
    margin-top: 40px

  }
  .nodeButtons > button {
    width: 40%
  }