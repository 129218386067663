.portalModalContainer {
  z-index: 4;
}

.modalOverlay {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 4;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 5;
  height: fit-content;
  max-height: 90vh;
  max-width: 90vw;
  width: auto;
  overflow: visible;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 20px 50px rgb(0 0 0 / 0.2);
}

.sharingLabelBlock {
  display: flex;
  flex-direction: column;
  z-index: 5;
  height: fit-content;
  max-height: 90vh;
  max-width: 90vw;
  width: auto;
  overflow: visible;
  overflow-y: auto;
  margin-bottom: 25px;
}

.sharingLabelBlockContent {
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-end;
}

.sharingLabelBlockContentLeft{
  width: 100%;
  padding-left: 15px;
  font-weight: bold;
  text-align: left;
  color: #006888;
}
.sharingLabelBlockContentLeftBl{
  padding-top: 20px;
  width: 100%;
  padding-left: 20px;  
  text-align: left;
  
}
.hiddenInput>div>input{
  display: none;
}
.shareUserInputContainer{
  text-align: left;  
  display: flex;
  align-self: stretch;
}
/* CLOSE BUTTON */
.modalTitle {
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-end;
}
.modalTitleText {
  width: 100%;
  padding-left: 20px;
  font-weight: bold;
  text-align: left;
  color: #006888;
}
.uploadCSVBlock{
  padding-top: 20px;
  padding-left: 40px;
}
.hiddenUpload{
  display: none;
}
.modalTitle > p {
  margin-right: 45px;
}
.modalCloseButton {
  margin: 0;
  padding: 0;
  width: 26px;
  margin-right: 10px;
  margin-top: 10px;
  height: 23px !important;
  border-radius: 5px;
  background-color: #006888;
}

.closeIcon{
  color: white;
}

.subModal {
  display: flex;
  margin: 25px;
}

.teamMemberList > p {
  margin-bottom: 8px;
}

.yourInfoPopup > div {
  display: flex;
  flex-direction: row;
  width: 110%;
  padding: 10px;
}
.yourInfoPopup > div > div > p {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.yourInfoPopup > div > h2 {
  margin-right: 8px;
}

.teamViewInfo > div {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  margin: 8px;
}

.teamViewInfo > div > p {
  margin-right: 8px;
}

.viewUserInfo > p {
  margin-bottom: 8px;
  white-space: nowrap;
}

.viewUserEmail > p {
  margin-bottom: 8px;
  margin-right: 8px;
}

.modalSubmitButton {
  margin-top: 30px;
}

.addUserInputs > input {
  margin: 8px;
  min-width: 300px;
  margin-right: 40px;
}

.teamItem > p {
  margin-right: 8px;
}

.userSponsorTeam > p:first-child {
  margin-right: 8px;
}

.nameAndPriv > p {
  margin-right: 8px;
  margin-bottom: 8px;
}

.teamUser > p {
  margin-bottom: 15px;
}

.teamUser > div {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.teamUser input {
  margin-left: 8px;
  width: 300px;
}

.requestInfoItem > p:first-child {
  margin-right: 8px;
}

.userToProd > button {
  align-self: center;
  margin-left: -70px;
}

.errorModal > button {
  margin-top: 20px;
}

.quantityModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.editProjectModal {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.editFormButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.editFormBtns-space-between{
  display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.public-switch{
  text-align: left;
  width: fit-content;
}

.orderQuantityInput {
  display: flex;  
  margin-top: 20px;
  margin-bottom: 20px;
  width: 55%;
  justify-content: space-between;
}

.sensorListOnQuantity {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  text-align: left;
  margin-bottom: 60px;
}

.mobileModal {
  display: flex;
  flex-direction: column;
  height: 20vh;
  justify-content: space-between;
  width: 100%;
}

.sensorsOnNode > p {
  text-transform: capitalize;
}

.naviagateModal {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  /* width: 55%; */
  justify-content: space-around;
}

.csvNodeModal {
  width: 500px;
  display: flex;
  flex-direction: column;
}
