.pagination {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
}
.arrowsAndPages {
    display: flex;
    align-self: center;
}
.showing {
    display: flex;
    padding: 15px;
    align-items: center;
}
.showing > p {
    margin-left: 10px
}