.landingArea {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  height: 400px;
  border: solid lightgrey 1px;
  box-shadow: 0 0 10px lightgrey;
  margin-top: 100px;
}

.landingChoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 300px;
  background-color: #006888;
  box-shadow: 0 0 10px lightgrey;
  white-space: normal;
}

.landingImage {
  margin-bottom: 25px;
}

.disclaimer {
  display: flex;
  margin-top: 40px;
  width: 33%;
  justify-content: space-between;
}

.landingArea-mobile {
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: solid lightgrey 1px;
  box-shadow: 0 0 10px lightgrey;
}

.landingChoice-mobile {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: fit-content;
  background-color: #006888;
  box-shadow: 0 0 10px lightgrey;
  white-space: normal;
}

.description {
  font-size: 8pt;
}

.comingSoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #006888;
  height: 300px;
  width: 30%;
  overflow: hidden;
  position: relative;
}

.comingSoon button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #006888;
  height: 300px;
  white-space: normal;
  cursor: unset;
}

@media screen and (max-width: 650px) {
  .comingSoon {
    margin: 10px;
    padding: 10px;
    width: 80% !important;
    height: 200px;
    white-space: normal;
  }

  .comingSoon button {
    margin: 10px;
    padding: 10px;
    width: 80%;
    height: fit-content;
    white-space: normal;
  }
}

.overlay {
  background: white;
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.triangle {
  border-width: 0 130px 130px 0;
  border-color: transparent #f7941e transparent transparent;
  border-style: solid;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
}

.triangle p {
  color: white;
  font-size: 14px;
}

.comingSoon-content {
  transform: rotateY(0deg) rotate(45deg);
  white-space: nowrap;
  position: absolute;
  top: 0;
  padding-left: 45px;
}
