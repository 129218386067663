.dropdownContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
  margin: 5px;
}

.dropdownContainer > button {
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.dropdownChoices {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  text-align: left;
  margin-bottom: 10px;
  z-index: 1;
  background-color: white;
  position: absolute;
  border: 1px solid lightgrey;
  margin-top: 30px
}

.dropdownOption {
  display: flex;
  justify-content: center;
  padding: 5px;
  min-width: fit-content;
  border-bottom: 1px solid lightgrey;
}

.dropdownOption:hover {
  background-color: lightgrey;
  color: teal;
}
