#mainNav {
    display: flex;
flex-direction: column
  }

    .announcements {
      height: fit-content;
      width: 100%;
      background-color: #c0dbe3;
      margin: auto;
      margin-bottom: 25px;
      padding: 8px;
      color: #006889;
      font-size: 1.15rem;
    }

    .announcements .material-icons{
      position: absolute;
    }
  
  .navLogo {
    display: flex;
    margin-left: 30px;
    margin-bottom: 5px;
  }

  #upperHalfNav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  
  .navList {
    display: flex;
  }
  
  .navbar-brand {
    padding: 0;
    height: 60px;
  }
  
  #navbarSupportedContent {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    margin-top: 35px;
  }

a.nav-link {
  color: #006888
 
}
  #accountNav {
    padding: 0;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px
  }
  
  .nav-line:not(:last-child) {
    border-right: 1px solid black;
  }
  
  .logo {
    width: 230px;
    margin-top: 15px;
  }
  .ioTlogo {
    width: 200px;
    height: 200px;
    
  }
  .nav-item {
    margin: 3px;
    align-self: center;
    text-decoration: none;
    color: #006888;
  }
  .nav-link {
    margin-left: 3px;
    margin-right: 3px;
  }
  
  .nav-link:hover {
    color: #138e8f;
  }

@media only screen and (max-width: 600px) {
  #accountNav {
    display: block;
    padding: 0;
    margin-top: 20px;
    margin-right: 20px
  }
  .navList {
    display: inline;
  }
  #upperHalfNav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

  }
  .navLogo {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
  .logo {
    width: 80%;
    margin-left: -15%;
  }
  .hamburgerMenu {
    margin-right: 20px;
    margin-top: 8px
  }
  #navbarSupportedContent {
    display: flex;
    flex-direction: row;
    height: 5vh;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 20px
  }
  #navbarSupportedContent > ul {
    display: flex;
    flex-direction: row;
    width: 30vw;
    align-items: center;
    justify-content: center;
    }
    .nav-item {
      margin: 3px;
      align-self: center;
      text-decoration: none;
      color: #006888;
      font-size: x-small;
    }
    .nav-item > a {
      font-size: small;
    }
}