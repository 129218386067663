.horizontalStepper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
}

.horizontalStepper > button {
  display: flex;
  align-content: center;
  text-align: center;
}

.horizontalStepper > button > svg{
align-self: center;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.stepNumber {
  border: 1px solid darkgrey;
  width: 25px;
  height: 25px;
  padding: 4px;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 5px;
}

.dividerLine {
  height: 1px;
  background-color: darkgrey;
  position: absolute;
  min-width: 65px;
  top: 25%;
  left: 78%;
}
.stepArea {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
}