
#nodes {
    width: 80%;
    display: flex;
    flex-direction: column;
}
.newNodeButton {
    margin-bottom: 15px;
    align-self: flex-start;
    margin-left: 20px
}