.projectStepContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-top: 40px;
}

.disclaimer {
  display: flex;
  justify-content: space-evenly;
}

.page {
  padding-top: 2rem;
}
