.qaqc-page{
    width: 80%;
    margin: auto;
    min-height: 50vh !important;
}

.qaqc-save-btn{
    float: right;
}

.qaqc-loader{
   display: flex;
   flex-direction: row;
   width: 80% !important;
   position: absolute;
   margin-top: 3rem;
   margin-bottom: 3rem;
}

.qaqc-loader-icon{
    margin: 0 auto; 
    width: 100%;
}

.qaqc-table{
    margin-top: 2rem;
    overflow-x: scroll;
    width: 100%;
    overflow-y: hidden
}

.contact-email{
    float: right;
    cursor: pointer;
}

.qaqc-table table{
    border-spacing: 0;
    width: 100%;
    
}

.qaqc-table thead {
    background-color: rgb(236, 236, 236);
}

 .qaqc-table thead>tr>th>span {
     margin-bottom: 5px;
     margin-left: 2px;
 }

 .qaqc-table th {
     margin: 0;
     padding: 0.6rem;
     border-bottom: 1px solid lightgrey;
     border-top: 1px solid lightgrey;
     white-space: nowrap;
     max-width: 200px;
     min-height: 70px;
     text-overflow: ellipsis;
 }

 .qaqc-table tr{
    border-top: 1px solid lightgrey;
 }

 .qaqc-table td {
    vertical-align: middle;
    text-align: center;
 }

   .qaqc-table td>a {
       color: #006888;
   }

   .qaqc-table td>a:visited {
       color: #69a03d;
   }

   .qaqc-table td>a:visited {
       color: #138e8f;
   }

   .qaqc-tooltip-outer{
       display: flex;
       width: 100%; 
       justify-content: center;
   }

   .qaqc-tooltip-inner{
       padding-top: 1px;
       padding-right: 4px;
   }