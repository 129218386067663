.legalContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 20px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
  }
  .legalContainer > h1 {
    margin-bottom: 50px;
    font-size: 30pt;
    align-self: center;
  }
  .legalContainer > h2 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .legalContainer > p {
    font-size: 12pt;
    margin-bottom: 20px;
    line-height: 15pt;
  }
  
  .copyrightProcedure {
    margin: 15px;
    display: flex;
    max-width: 85%;
    flex-direction: column;
    align-self: center;
  }
  
  .copyrightProcedure > p {
    align-self: flex-start;
  }