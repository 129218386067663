.userInfo {
    display: flex;
}

.userInfo > p {
    margin-right: 10px;
    margin-top: 10px
}

.userInfo > div {
    text-align: left;
}

.userInfoContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    border: 1px darkgrey solid;
    justify-content: space-evenly;
    max-width: 400px;
    min-height: 300px;
    margin: 10px;
    box-shadow: 0 0 10px #dfdddd;
    padding: 16px;
}