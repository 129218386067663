.projectStepContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-top: 40px;
}

.addProjectTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}
