#mainSetupVideo {
  display: flex;
  flex-direction: column;
  margin-top: 7.8rem;
}
.setupInstructionsArea{
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  margin-right: 10px
}
.nodeSetupContainer {
  display: flex;
  align-items: space-between;
  min-width: 90%;
  height: fit-content;
  padding-top: 4rem;
}
.accordionContainer {
  display: flex;
  align-self: flex-start;
  justify-content: stretch;
  width: 100%;
  margin: 0;
  padding: 0;
}
.startVideo {
  margin-bottom: 18px
}
.stepInstructions,
.stepComponents {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px;
  margin-right: 16px;
}
.stepComponents > div > p {
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 16px;
}
.stepInstructions > p {
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 16px;
}
.stepVideo {
  margin-left: 20px;
}
.materialsContainer {
  display: flex;
  flex-direction: row;
  height: 300px;
  max-width: 480px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 15px;
  margin-right: 0px
}
.materials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.materials > p {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 20px;
}

.tipContainer {
  display: flex;
  flex-direction: column;
  width: 480px;
  text-align: left;
}

.tipContainer > p {
  margin-top: 3px
}

.transcriptContainer {
  width: 480px;
}

.transcript {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: stretch;
  position: relative;
  height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  z-index: 6;
}

.transcript > p {
  margin-top: 8px;
  margin-right: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.setupContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#mainSetupVideoMobile {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  height: auto;
}