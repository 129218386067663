.projectListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fafafa;
}

.tabContainer {
  width: 100%
}

.projectCardMargin{
  margin: 10px
}

#projectList {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  justify-self: center;
}

.addProject {
  display: flex;
  align-self: flex-start;
  margin-left: 80px;
  margin-bottom: 25px;
  margin-top: 25px;
}

@media only screen and (max-width: 600px) {
  #projectList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #projectListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  
  .farmCard {
    display: flex;
    border: 1px darkgrey solid;
    min-width: 100%;
  }
}
