.accordion {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: flex-start;
  width: 100%;
}

.accordionButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  height: 40px;
  border-bottom: 1px solid darkgrey;
  white-space: nowrap;
}

.accordionContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 500px;

  padding-top: 20px;
  padding-bottom: 20px;
}

.arrow {
  margin-left: 20px
}
